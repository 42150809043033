/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// debouncing function from John Hann: http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
(function($,sr){
	var debounce = function (func, threshold, execAsap) {
			var timeout;
			return function debounced () {
					var obj = this, args = arguments;

					function delayed () {
						if (!execAsap) {
							func.apply(obj, args);
							timeout = null;
						}
					}

					if (timeout) {
						clearTimeout(timeout);
					} else if (execAsap) {
						func.apply(obj, args);
					}

					timeout = setTimeout(delayed, threshold || 800); 
			};
	};
	jQuery.fn[sr] = function(fn){ return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
})(jQuery,'smartresize');

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
				// Scroll Down indicater.
				$('.scroll-down').click(function(){
					$('html, body').animate(
						{
							scrollTop: $('#scroll-target').offset().top
						}, {
							duration: 'slow'
						}
					);
				});

				var el = document.querySelector('.iso-grid');
				if(el !== null) {
					window.iso = new Isotope( el, { itemSelector: '.iso-item' });
					iso.on( 'arrangeComplete', function() {
						AOS.refresh();
					});
					$('.filter-bar').on( 'click', 'button', function() {
						$('.filter-bar button').each( function(idx, el){
							$(el).removeClass('active');
						});
						$(this).toggleClass('active');
						var filterValue = $(this).attr('data-filter');
						iso.arrange({ filter: filterValue });
					});
				}

				//Popups
				$('.popup-tint').on( 'click', function(e){
					if(e.target.className === "popup-tint") {
						$('input.mce_inline_error').removeClass('mce_inline_error');
						$('div.mce_inline_error').remove();
						$('.popup-tint').fadeOut();
					}
				});

				$('.popup .close').on( 'click', function(e){
					$('input.mce_inline_error').removeClass('mce_inline_error');
					$('div.mce_inline_error').remove();
					$('.popup-tint').fadeOut();
				});

				$('#newsletter-subscribe').on( 'click', function(){
					if( $('#newsletter-email').val() !== "" ) {
						$('#mc_embed_signup #mce-EMAIL').val( $('#newsletter-email').val() );
					}

					$('.popup-tint').fadeIn();
				});

				AOS.init({ once: true });

			},
			finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
				$(window).load(function(){
					if(window.iso) { iso.layout(); }
					
					$('.square').each(function(i, el) {
						$(el).height($(el).width());
					});

					$(window).smartresize(function(){
						$(window).trigger('resize.px.parallax');
						
						$('.square').each(function(i, el) {
							$(el).height($(el).width());
						});
					});

				});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.